<template>
  <div v-if="componentData?.content_about_template_icon_source" class="content-center mx-auto mx-lg-0 icon-block" v-html="  componentData?.content_about_template_icon_source"></div>
  <p v-if="componentData?.content_about_template_text" v-html="componentData?.content_about_template_text"></p>
  <ButtonCta
    v-if="componentData?.content_about_template_add_cta == 1"
    :target="componentData?.content_about_template_cta_target"
    :href="componentData?.content_about_template_cta_page_link? componentData?.content_about_template_cta_page_link : ''"
    :file="componentData?.content_about_template_cta_file? componentData?.content_about_template_cta_file : ''"
    :theme="componentData?.content_about_template_cta_theme"
    :size="componentData?.content_about_template_cta_size"
    :label="componentData?.content_about_template_cta_label"
    :arrow="componentData?.content_about_template_add_arrow"
  />  
</template>
<script setup>
	// props
	const props = defineProps({
	  data: Object,
	});

	// data
	const componentData = props?.data || {};


  
</script>